<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" v-loading="formLoading" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="dataForm.roleName" placeholder="角色名称"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item size="mini" label="授权">
        <el-tree :data="menuList" :props="menuListTreeProps" node-key="id" ref="menuListTree" show-checkbox>
        </el-tree>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  treeDataTranslate
} from '@/utils'
export default {
  data() {
    return {
      visible: false,
      menuList: [],
      menuListTreeProps: {
        label: 'name',
        children: 'children'
      },
      dataForm: {
        id: 0,
        roleName: '',
        remark: ''
      },
      dataRule: {
        roleName: [{
          required: true,
          message: '角色名称不能为空',
          trigger: 'blur'
        }]
      },
      formLoading: false,
      btnLoading: false,
      tempKey: -666666 // 临时key, 用于解决tree半选中状态项不能传给后台接口问题. # 待优化
    }
  },
  methods: {
    async init(id) {
      this.visible = true
      this.dataForm.id = id || 0
      let res = await this.$get('/platform-admin/menu/select');
      if (res && res.code == 0) {
        this.menuList = res.data;
        console.log(this.menuList)
        this.$nextTick(() => {
          this.$refs.menuListTree && this.$refs.menuListTree.setCheckedKeys([]);
        })
      }
      if (this.dataForm.id) {
        this.formLoading = true;
        let re = await this.$get(`/platform-admin/role/${this.dataForm.id}`);
        this.formLoading = false;
        if (re && re.code == 0) {
          this.dataForm.roleName = re.data.name;
          this.dataForm.remark = re.data.remark;
          var idx = re.data.menuIdList.indexOf(this.tempKey)
          if (idx !== -1) {
            re.data.menuIdList.splice(idx, re.data.menuIdList.length - idx)
          }
          re.data.menuIdList.forEach((element) => {
            var node = this.$refs.menuListTree.getNode(element);
            if (node && node.isLeaf) {
              this.$refs.menuListTree.setChecked(node, true);
            }
          }) 
        }
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          this.btnLoading = true;
          let param = {
            id: this.dataForm.id || undefined,
            name: this.dataForm.roleName,
            remark: this.dataForm.remark,
            menuIdList: [].concat(this.$refs.menuListTree.getCheckedKeys()).concat(this.$refs.menuListTree.getHalfCheckedKeys())
          }
          let res
          if (this.dataForm.id) {
            res = await this.$put('/platform-admin/role', param);
          } else {
            res = await this.$post('/platform-admin/role', param);
          }
          this.btnLoading = false;
          if (res && res.code == 0) {
            this.visible = false
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.$emit('refreshDataList')
              }
            })
          }
        }
      })
    }
  }
}
</script>
